.MuiToolbar-root img {
  width: 50px;
  margin: 20px auto;
}

.MuiList-root a {
  text-decoration: none;
}

.MuiList-root .active .MuiButtonBase-root {
  background: rgb(255, 255, 255);
  border-left: #5c3921 3px solid;
}

.active .MuiTypography-root {
  font-weight: 700;
  color: #5c3921;
}

.MuiList-root .active .MuiButtonBase-root .MuiListItemIcon-root {
  color: #5c3921;
}
