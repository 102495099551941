@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(179, 179, 179);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(155, 155, 155);
}

.customUpload input[type="file"] {
  display: none;
}
.customUpload {
  width: 100%;
  background: #21837e;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 14px;
  color: white;
  letter-spacing: 0.6px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.customUpload:hover {
  background: #175b58;
}

.tripImage:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: all ease 0.5s;
}

.tripImage:hover:after {
  background: rgba(0, 0, 0, 0);
}

.tripImage:hover + .imgDeleteBtn {
  visibility: visible;
}

.MuiButtonBase-root.MuiIconButton-root.imgDeleteBtn {
  transition-property: color, background-color, -webkit-transform;
  transition-property: color, background-color, transform;
  transition-property: color, background-color, transform, -webkit-transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  background: rgba(244, 238, 234, 0.8) !important;
  padding: 4px !important;
}

.MuiButtonBase-root.MuiIconButton-root.imgDeleteBtn:hover {
  visibility: visible;
  background-color: rgba(33, 131, 126, 1) !important;
  color: rgba(244, 238, 234, 1);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.MuiDataGrid-root {
  cursor: pointer;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeaderCheckbox,
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none;
}
.MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: 700;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.dragDrop input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.dragDrop:hover,
.dragDrop.dragover {
  opacity: 0.6;
}

#travelSvg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 500px;
  opacity: 0.25;
}

@media only screen and (max-width: 900px) {
  #travelSvg {
    max-width: 70%;
    top: 58%;
  }

  .content {
    left: 0;
    width: 100vw;
    padding: 20px;
  }

  /* width */
  ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }
}

/* Display warning screen when on landscape and width less than 900px */
.warningScreen {
  display: none;
}

.rotateWarning {
  -webkit-animation: rotateWarn 3s ease-in-out infinite;
          animation: rotateWarn 3s ease-in-out infinite;
}

@-webkit-keyframes rotateWarn {
  0% {
    -webkit-transform: scale(1) rotate(135deg);
            transform: scale(1) rotate(135deg);
  }
  30% {
    -webkit-transform: scale(1.4) rotate(35deg);
            transform: scale(1.4) rotate(35deg);
  }
  40% {
    -webkit-transform: scale(1.4) rotate(55deg);
            transform: scale(1.4) rotate(55deg);
  }
  50% {
    -webkit-transform: scale(1.4) rotate(45deg);
            transform: scale(1.4) rotate(45deg);
  }
  100% {
    -webkit-transform: scale(1.4) rotate(45deg);
            transform: scale(1.4) rotate(45deg);
  }
}

@keyframes rotateWarn {
  0% {
    -webkit-transform: scale(1) rotate(135deg);
            transform: scale(1) rotate(135deg);
  }
  30% {
    -webkit-transform: scale(1.4) rotate(35deg);
            transform: scale(1.4) rotate(35deg);
  }
  40% {
    -webkit-transform: scale(1.4) rotate(55deg);
            transform: scale(1.4) rotate(55deg);
  }
  50% {
    -webkit-transform: scale(1.4) rotate(45deg);
            transform: scale(1.4) rotate(45deg);
  }
  100% {
    -webkit-transform: scale(1.4) rotate(45deg);
            transform: scale(1.4) rotate(45deg);
  }
}

@media only screen and (orientation: landscape) and (max-height: 575.98px) and (max-width: 900px) {
  .warningScreen {
    display: flex;
  }

  body {
    pointer-events: none;
    overflow: hidden;
  }
}

.MuiToolbar-root img {
  width: 50px;
  margin: 20px auto;
}

.MuiList-root a {
  text-decoration: none;
}

.MuiList-root .active .MuiButtonBase-root {
  background: rgb(255, 255, 255);
  border-left: #5c3921 3px solid;
}

.active .MuiTypography-root {
  font-weight: 700;
  color: #5c3921;
}

.MuiList-root .active .MuiButtonBase-root .MuiListItemIcon-root {
  color: #5c3921;
}

